import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { CalendarioPage } from '../calendario/calendario.page';

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.page.html',
  styleUrls: ['./reportes.page.scss'],
})
export class ReportesPage implements OnInit {

  data_send:any = { inicial:"", final:"" };
  user:any;
  objetos:any = [];
  objetoSelecionado:any;
  id:any;

  constructor( public navCtrl:NavController, public modalCtrl:ModalController, private _api:ApiService, 
              public loadingController:LoadingController, private route: ActivatedRoute, private alertCtrl: AlertController ) { 
    this.user = localStorage.getItem("user");
    this.id = this.route.snapshot.params.id;
    this.objetoSelecionado = this.id;
  }

  ngOnInit() {
    this.getObjetos();
  }

  change( event:any ){
    console.log(event);
  }

  async getObjetos(){

    const loading = await this.loadingController.create({
      message:"Cargando..."
    });

    loading.present();

    let data_send = { user: this.user };
    this._api.getObjetos( data_send ).subscribe((data:any)=>{
      loading.dismiss();
      this.objetos = data.data;
      console.log(data);
    },(err:any)=>{
      loading.dismiss();
    });
  }

  async fechas( tipo:number ){
    let modal = await this.modalCtrl.create({
      component: CalendarioPage,
      cssClass:"calendario-modal",
      componentProps: {
        'tipo': tipo,       
      }      
    });

    modal.onDidDismiss().then((data:any)=>{
      console.log(data); 
      if(data){
        if( data.data.tipo == 1 ){
          this.data_send.inicial = data.data.fecha;
        }else{
          this.data_send.final = data.data.fecha;
        }
      } 
    });

    return await modal.present();
  }

  consultar(){
    const filter = this.objetos.filter( (obj:any)=>{
      return obj.imei == this.objetoSelecionado;
    });
    console.log("SE consulta", this.objetoSelecionado, this.data_send, filter );
    if( !this.data_send.inicial ){
      return this.presentAlert("La fecha inicial es requerida.");
    }

    if( !this.data_send.final ){
      return this.presentAlert("La fecha final es requerida.");
    }

    let inicial = new Date(this.data_send.inicial).getTime();
    let final   = new Date(this.data_send.final).getTime();
    this.navCtrl.navigateForward(`/reportes-tab/mapa/${this.objetoSelecionado}/${inicial}/${final}`);
    
  }

  async presentAlert( mensaje:string ) {
    const alert = await this.alertCtrl.create({      
      subHeader: 'Mensaje',
      message: mensaje,
      buttons: ['OK'],
    });

    await alert.present();
  }

}
